.meeting__callout {
  position: relative;
  background-color: var(--color-white);
  padding: 2rem 3rem;
  margin-top: 40px;
}

.meeting__callout-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.meeting__callout-content > * {
  margin: auto;
}

.meeting__callout-info {
  text-align: center;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 30rem;
}

.meeting__callout-heading {
  color: var(--color-title-orange);
}

.meeting__callout--off-piste .meeting__callout-heading {
  color: var(--color-action-orange);
}

.meeting__callout-link {
  font-weight: 700;
}

.meeting__callout-speech {
  position: absolute;
  top: -40;
  left: 2.5rem;
  width: 0;
  height: 0;
  border-right: 50px solid transparent;
  border-bottom: 40px solid var(--color-white);
}

.meeting__callout-close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
}
