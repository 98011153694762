.drop-down-menu {
  position: relative;
  display: inline-block;
  cursor: pointer;
  padding: 0.5rem 0.5rem;
}

.drop-down-menu__content {
  display: none;
  position: absolute;
  right: 0;
  background-color: var(--color-white);
  min-width: 160px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  z-index: 1;
}

.drop-down-menu__content.drop-down-menu__content--open {
  display: block;
}

/* .drop-down-menu:hover .drop-down-menu__content {
  display: block;
} */
