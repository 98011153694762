.career-motivators {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.career-motivators__group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.career-motivators__group-brace {
  grid-column: 1/-1;
}

.career-motivators__group-explainer {
  grid-column: 1/-1;
  text-align: center;
  line-height: 2rem;
}

.career-motivator {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.career-motivator__score {
  font-size: 1.5rem;
  font-weight: 800;
  color: var(--color-voco-teal);
  margin-bottom: 0.5rem;
}

.career-motivator__title {
  text-align: center;
}

.career-motivators__group--green .career-motivators__group-brace {
  height: 1rem;
  grid-column: 1/-1;
  border: 4px solid var(--color-motivator-green);
  border-top: 0;
}

.career-motivators__group--green .career-motivator__score,
.career-motivators__group--green .career-motivators__group-explainer {
  color: var(--color-motivator-green);
}

.career-motivators__group--orange .career-motivators__group-brace {
  height: 1rem;
  grid-column: 1/-1;
  border: 4px solid var(--color-motivator-orange);
  border-top: 0;
}

.career-motivators__group--orange .career-motivator__score,
.career-motivators__group--orange .career-motivators__group-explainer {
  color: var(--color-motivator-orange);
}

@media only screen and (max-width: 950px) {
  .career-motivators {
    grid-template-columns: 1fr;
  }
  .career-motivators__group {
    margin-bottom: 1rem;
  }
}
