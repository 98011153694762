.meeting-wrapper {
  position: relative;
  width: 100%;
  height: auto;
  background-color: var(--color-meeting-background);
  overflow: hidden;
}

.meeting {
  overflow: hidden;
}

.meeting-frame {
  position: relative;
  width: 100%;
  height: calc(100vh - 220px);
  border: 0;
}

.meeting-frame.meeting-frame-expands {
  height: calc(100vh - 228px);
  animation: expand-video 1s ease 2s normal both;
}

@keyframes expand-video {
  from {
    height: calc(100vh - 228px);
  }
  to {
    height: calc(100vh - 40px);
  }
}

.meeting-nav {
  position: absolute;
  top: 0.5rem;
  left: calc(((100vw - var(--content-width)) / 2) - 1.5rem);
  z-index: 1;
  padding: 0 1.5rem;
}

.meeting-nav__logo {
  height: 5rem;
  width: auto;
}

/* .meeting-wrapper--anonymous .meeting-frame {
  height: calc(100vh - 120px);
}

.meeting-wrapper--anonymous iframe.meeting-frame {
  height: calc(100vh - 127.5px);
} */

.meeting-frame--welcome {
  display: flex;
  justify-content: center;
  align-items: center;
}

.meeting__welcome {
  position: relative;
  background-color: var(--color-white);
  padding: 2rem 3rem;
}

.meeting__welcome-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.meeting__welcome-content > * {
  margin: auto;
}

.meeting__welcome-avatars {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 1rem;
}

.meeting__welcome-avatar {
  width: 4rem;
  height: 4rem;
}

.meeting__welcome-info {
  text-align: center;
  margin-bottom: 1.5rem;
}

.meeting__welcome-date {
  text-align: center;
  padding-bottom: 1.5rem;
}

.meeting__welcome-link {
  margin-top: 1rem;
}

.meeting__welcome-speech {
  position: absolute;
  bottom: -40;
  right: 20;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-top: 40px solid var(--color-white);
}

.meeting_content__buttons {
  display: flex;
}

.meeting_content__button {
  font-weight: 700;
  border-radius: 22px;
  background-color: var(--button-grey);
  margin-top: 1.25rem;
  margin-right: 1.5rem;
  flex-shrink: 0;
}

.meeting_content__link {
  margin-top: 1.25rem;
  margin-right: 1.5rem;
  font-weight: 700;
}

.meeting_content__button--active {
  background-color: var(--voco-button-blue);
}

.meeting_content__notes {
  margin-top: 1.5rem;
}

@media only screen and (max-width: 1134px) {
  .meeting-nav {
    left: 0rem;
  }

  .meeting-nav__logo {
    height: 3rem;
    width: auto;
  }
  .meeting_content__buttons {
    flex-wrap: wrap;
    padding-left: 1.25rem;
  }
}
