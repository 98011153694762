.member-profile-info__header {
  display: grid;
  grid-template-columns: 4rem auto;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: 'a b' 'a c' 'a d';
  column-gap: 0.5rem;
}

.member-profile-info__avatar {
  width: 4rem;
  height: 4rem;
  /* grid-row: 1/3; */
  grid-area: a;
  align-self: center;
}

.member-profile-info__name {
  align-self: center;
  grid-area: c;
  font-weight: 700;
  line-height: 1rem;
}

.member-profile-info__role {
  align-self: start;
  grid-area: d;
  line-height: 1rem;
}

.member-profile-info__text {
  margin: 1rem 0;
}
