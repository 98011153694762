.member-picker__members {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.member-picker__member {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2.25rem 1rem;
  cursor: pointer;
  width: 8rem;
}

.member-picker__member.member-picker__member--no-click {
  cursor: default;
}

.member-picker__member.member-picker__member--disabled {
  cursor: default;
  pointer-events: none;
}

.member-picker__member--disabled .member-picker__member-add g,
.member-picker__member--disabled .member-picker__member-add line {
  stroke: #9ab7bc;
}

.member-picker__member-avatar {
  width: 4rem;
  height: 4rem;
  margin-bottom: 0.5rem;
}

.member-picker__member-add {
  width: 4rem;
  height: 4rem;
  margin-bottom: 0.5rem;
}

.member-picker__member-looking {
  height: 2rem;
  margin: 1rem 1rem 1.5rem;
}

.member-picker__member-avatar .avatar__avatar-image:hover {
  outline: 1px solid var(--color-action-orange);
}

.member-picker__member-avatar--selected .avatar__avatar-image,
.member-picker__member-avatar--selected .avatar__avatar-image:hover {
  outline: 4px solid var(--color-action-orange);
}

.member-picker__member-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 8rem;
}

.member-picker__selected-member {
  background-color: var(--color-voco-teal);
  padding: 0.25rem;
  margin-bottom: 2rem;
  color: var(--color-white);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.member-picker__selected-member-link {
  text-decoration: underline;
  font-weight: 700;
  margin: 0 1rem;
  justify-self: end;
}

.member-picker__selected-member-previous {
  margin: 0 1rem;
  justify-self: center;
}

.member-picker__selected-member-next {
  margin: 0 1rem;
  justify-self: start;
}

.member-picker__selected-member-date {
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .member-picker__members {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .member-picker__member {
    padding: 0 0.25rem 2rem;
  }

  .member-picker__selected-member {
    display: flex;
  }

  .member-picker__selected-member-previous {
    display: none;
  }

  .member-picker__selected-member-link {
    margin: 0 0 0 auto;
  }

  .member-picker__selected-member-next {
    margin: 0;
  }

  .member-picker__member-avatar .avatar__avatar-image:hover {
    outline: none;
    border: 1px solid var(--color-action-orange);
  }

  .member-picker__member-avatar--selected .avatar__avatar-image,
  .member-picker__member-avatar--selected .avatar__avatar-image:hover {
    outline: none;
    border: 4px solid var(--color-action-orange);
  }
}
