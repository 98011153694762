.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}

.loader__message {
  display: flex;
  font-family: league-gothic, sans-serif;
  font-size: 1.5rem;
}

.loader__message-text {
  margin-left: 1rem;
}
