.header {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  align-content: stretch;
}

.content-wrapper {
  max-width: var(--content-width);
  margin: 0 auto;
}
