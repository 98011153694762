.career-interests {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 1.25rem;
  row-gap: 1rem;
  padding: 1rem 4rem 3rem;
}

.career-interests__interest {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  background-image: url(../../svgs/interest-bubble.svg);
  width: 240px;
  height: 55px;
  padding: 1rem;
  padding-left: 2rem;
  font-weight: 800;
  text-align: center;
  color: var(--color-voco-teal);
  justify-self: center;
}

@media only screen and (max-width: 920px) {
  .career-interests {
    grid-template-columns: 1fr 1fr;
    row-gap: 0.5rem;
  }
}

@media only screen and (max-width: 680px) {
  .career-interests {
    grid-template-columns: 1fr;
    row-gap: 0.5rem;
    padding: 0;
    margin-bottom: 2rem;
  }
}
