.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 999;
  background-color: var(--color-modal-background);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal__wrapper {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.modal__content {
  flex-basis: 920px;
  background-color: var(--color-white);
  margin-left: 4.5rem;

  padding: 1rem 1rem 2rem;
}

.modal__content-heart {
  margin: 1rem 0;
}

.modal__content-header {
  border-bottom: 4px solid var(--color-text-primary);
  margin-bottom: 1rem;
  display: flex;
}

.modal__content_info {
  float: left;
  align-self: flex-end;
  margin-left: 0.5rem;
  margin-bottom: 0.2rem;
}

.modal__content_title {
  font-family: league-gothic, sans-serif;
  font-size: 2.5rem;
  line-height: 3rem;
  text-transform: uppercase;
  flex-shrink: 1;
}

.modal__content_action {
  margin-left: auto;
  margin-bottom: 1rem;
  flex-shrink: 1;
}

.modal__content-action-in-progress {
  margin-left: auto;
  flex-shrink: 1;
  padding: 0.5rem 1rem;
}

.modal__dismiss {
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 1rem;
}

.modal__dismiss-button {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  /* body {
    overflow: hidden !important;
    position: absolute;
  } */

  .modal {
    max-width: 100vw;
    overflow: scroll;
    align-items: flex-start;
    padding: 2rem 0;
  }

  .modal__wrapper {
    max-width: 100vh;
    flex-direction: column-reverse;
  }

  .modal__content-header {
    flex-direction: column;
  }

  .modal__content_info {
    align-self: flex-start;
    margin-left: 0;
  }

  .modal__content {
    flex-basis: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .modal__dismiss {
    margin-left: auto;
    margin-bottom: 0.5rem;
  }
}
