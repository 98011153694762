.info-card {
  border-style: solid;
  border-width: 4px;
  position: relative;
  display: flex;
}

.info-card__date {
  width: 100px;
  height: 100px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info-card__date-day {
  font-size: 2rem;
  font-weight: 800;
}

.info-card__date-month,
.info-card__no-date-text {
  font-size: 0.875rem;
  font-weight: 700;
}

.info-card__no-date-icon {
  margin-bottom: 0.5rem;
}

.info-card__icon {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.info-card__text-icon {
  font-size: 2rem;
  font-weight: 800;
  color: var(--color-white);
}

.info-card__text {
  flex: 1;
  padding: 1rem;
  display: flex;
  height: 100px;
  overflow: hidden;
  align-items: center;
  justify-content: left;
}

.info-card__link-text {
  margin-top: 0.5rem;
  color: var(--color-white);
  text-align: center;
  font-weight: 700;
  font-style: italic;
  text-decoration: underline;
  line-height: 1rem;
}

.info-card.info-card--orange {
  border-color: var(--color-action-orange);
}

.info-card.info-card--orange .info-card__icon {
  background-color: var(--color-action-orange);
}

.info-card.info-card--orange .info-card__date {
  color: var(--color-action-orange);
}

.info-card.info-card--teal {
  border-color: var(--color-voco-teal);
}

.info-card.info-card--teal .info-card__icon {
  background-color: var(--color-voco-teal);
}

.info-card.info-card--teal .info-card__date {
  color: var(--color-voco-teal);
}

.info-card .drop-down-menu {
  position: absolute;
  top: -0.5rem;
  right: 0.25rem;
}

.info-card .drop-down-menu__icon {
  width: 28px;
  height: 20px;
  padding: 0.5rem 0.25rem;
  background-color: #f7feffc0;
}

@media only screen and (max-width: 1024px) {
  .info-card__text {
    padding: 0.75rem;
  }
}

@media only screen and (max-width: 390px) {
  .info-card__text {
    font-size: 0.75rem;
  }
}
