.match-journey-section__guides {
  margin-bottom: 4rem;
}

.match-journey-section__empty-state {
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 2rem;
}

.match-journey-section__empty-state-text {
  color: var(--color-action-orange);
}

.match-journey-section__intro {
  margin: 3rem auto;
}

@media only screen and (max-width: 1080px) {
  .match-journey-section__empty-state-text {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}
