.member-info-grid {
  max-width: 768;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 3rem;
  row-gap: 2rem;
}

.member-info__label {
  font-size: 0.875rem;
}

.member-info__value {
  font-weight: 700;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 768px) {
  .member-info-grid {
    grid-template-columns: 1fr 1fr;
    row-gap: 1.5rem;
  }
}

@media only screen and (max-width: 500px) {
  .member-info-grid {
    grid-template-columns: 1fr;
  }
}
