.theme-page {
  margin-top: 4.5rem;
}

.theme-page__guide-button {
  /* display: inline-block; */
  font-weight: 700;
  border-radius: 22px;
}

.theme-page__footer {
  display: flex;
}

.theme-page__footer .theme-page__guide-button {
  margin: 2rem auto;
}

@media only screen and (max-width: 1080px) {
  .theme-page {
    margin-top: 2rem;
  }
}
