.button {
  border-radius: 26px;
  line-height: 2rem;
  display: inline-block;
  cursor: pointer;
}

.button:disabled {
  background-color: var(--text-hover-color);
  cursor: default;
}

.button--primary {
  color: var(--color-white);
  background-color: var(--voco-button-blue);
  padding: 0.5rem 2rem;
}

.button--secondary {
  padding: 0.5rem 2rem;
  background-color: var(--text-hover-color);
}
