.banner {
  text-align: center;
  height: 2rem;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
  padding: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .banner {
    font-size: 0.75rem;
  }
}
