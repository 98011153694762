.text-section {
  background-color: var(--color-white);
  padding: 1rem;
  line-height: 1.5rem;
}

@media only screen and (max-width: 768px) {
  .text-section {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
}
