.nav-button {
  font-weight: 700;
  font-size: 0.75rem;
  padding: 0.75rem;
  margin: 0 0.5em;
}

.nav-link-button-active .nav-button {
  background-color: var(--color-voco-faded);
}

.nav-button:hover {
  color: var(--text-hover-color);
}

.nav-link-button-active .nav-button:hover {
  color: var(--color-text-primary);
}

.nav-button__icon {
  display: none;
  height: 27px;
  width: auto;
}

@media only screen and (max-width: 768px) {
  .nav-button {
    padding: 0;
    margin: 0 0.75rem;
  }

  .nav-link-button-active .nav-button {
    background-color: transparent;
  }

  .nav-link-button-active .nav-button__icon {
    fill: var(--color-voco-faded);
  }

  .nav-button__icon {
    display: inline-block;
  }
  .nav-button__label {
    display: none;
  }
}
