.guide-page {
  margin-top: 4.5rem;
}

.guide-page__banner {
  display: block;
  margin: 1.5rem auto 0;
  max-width: 100%;
}

.guide-page__agenda-item,
.guide-page__body-item {
  margin-top: 1.25rem;
}

@media only screen and (max-width: 1080px) {
  .guide-page {
    margin-top: 2rem;
  }
}
