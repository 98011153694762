.schedule-meeting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}

.schedule-meeting__members {
  margin: 0 auto;
  display: flex;
  gap: 2rem;
  align-items: center;
}

.schedule-meeting__members-avatar {
  width: 4rem;
}

.schedule-meeting__lock-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

.schedule-meeting__sad-cat {
  max-width: 20rem;
  margin: 2rem auto;
  display: block;
}

.schedule-meeting__submit-button {
  display: block;
  margin: 1rem auto 0;
}

.schedule-meeting__help-link {
  display: block;
  margin: 1rem auto 0;
  margin-top: 1rem;
  font-weight: 700;
  text-align: center;
}

.schedule-meeting__scheduled-date {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  margin: 2.5rem auto;
}

@media only screen and (max-width: 1080px) {
  .schedule-meeting {
    gap: 1rem;
  }
}
