.actions__cards {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.25rem;
  row-gap: 1.5rem;
  /* grid-auto-flow: column; */
}

.actions-empty-state {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}

.actions-empty-state__message {
  font-family: league-gothic, sans-serif;
  font-size: 1.5rem;
}

@media only screen and (max-width: 825px) {
  .actions__cards {
    grid-template-columns: 1fr;
    row-gap: 0.5rem;
  }
}
