.date-tile {
  width: 101px;
  height: 101px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.date-tile--dark-teal {
  background-color: var(--color-voco-teal);
  color: var(--color-white);
}

.date-tile--orange {
  color: var(--color-action-orange);
}

.date-tile--disabled {
  background-color: var(--text-hover-color);
}

.date-tile__day,
.date-tile__month {
  font-size: 0.875rem;
  font-weight: 700;
}

.date-tile__date {
  font-size: 1.75rem;
  font-weight: 800;
  line-height: 2.25rem;
}

@media only screen and (max-width: 1080px) {
  .date-tile {
    width: 70px;
    height: 70px;
  }

  .date-tile__date {
    font-size: 1.25rem;
    font-weight: 800;
    line-height: 1.25rem;
  }

  .date-tile__day,
  .date-tile__month {
    font-size: 0.625rem;
    font-weight: 700;
  }
}
