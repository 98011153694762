.content-section {
  padding: 1rem;
}

.content-section.content-section--subsection {
  padding: 0;
}

.content-section__heading {
  display: flex;
  align-items: baseline;
  margin-bottom: 2rem;
  border-bottom: 4px solid var(--color-text-primary);
  padding-bottom: 0.25rem;
}

.content-section--subsection .content-section__heading {
  margin-bottom: 1rem;
}

.content-section__heading.content-section__heading--with-sections {
  border-bottom: none;
  margin-bottom: 1rem;
}

.content-section__title {
  font-family: league-gothic, sans-serif;
  font-size: 2.5rem;
  line-height: 3rem;
  text-transform: uppercase;
  padding-right: 1rem;
}

.content-section__heading--with-sections .content-section__title {
  border-bottom: 4px solid var(--color-text-primary);
  margin-bottom: 2rem;
  padding-right: 4rem;
}

.content-section__heading--subsection .content-section__title {
  font-size: 1.875rem;
  line-height: 2rem;
}

.content-section__info {
  align-self: flex-end;
}

.content-section__action {
  margin-left: auto;
}

.content-section__action-button {
  padding-top: 0;
  padding-bottom: 0;
  align-self: flex-start;
}

.content-section__action--disabled {
  cursor: default;
  color: var(--text-hover-color);
}

.content-section__content {
  margin-bottom: 1rem;
}

.content-section__title--mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .content-section__heading {
    padding-bottom: 0.5rem;
  }

  .content-section__title--desktop {
    display: none;
  }

  .content-section__title--mobile {
    display: inherit;
  }

  .content-section__heading.content-section__heading--with-sections {
    width: 75%;
  }
}
