.edit-intro-text__text {
  width: 100%;
  border: 1px solid var(--color-text-primary);
  padding: 1rem;
  height: 12.5rem;
}

.edit-intro-text__count {
  float: right;
  color: var(--color-voco-teal);
}
