.journey-step {
  position: relative;
  display: flex;
  align-items: stretch;
  padding-bottom: 3rem;
}

.journey-step__no-date {
  width: 101px;
  height: 101px;
}

.journey-step__path {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
  width: 100px;
}

.journey-step__path-bar {
  position: absolute;
  height: 100%;
  width: 6px;
  left: 148px;
  /* top: 0;
  left: calc(1rem + 56.57px - 3px); */
  background-color: var(--color-voco-darker-teal);
}

.journey-step:first-child .journey-step__path-bar {
  top: 25px;
  height: calc(100%-25px);
}

.journey-step:last-child .journey-step__path-bar {
  bottom: 100px;
  height: calc(100% - 100px);
}

.journey-step--orange .journey-step__path-bar {
  background-color: var(--color-action-orange);
}

.journey-step__diamond {
  transform: rotate(45deg);
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--color-white);
  border: 8px solid var(--color-voco-darker-teal);
}

.journey-step--orange .journey-step__diamond {
  border-color: var(--color-action-orange);
}

.journey-step--orange .journey-step__diamond.journey-step__diamond--filled {
  background-color: var(--color-voco-lighter-faded);
}

.journey-step--teal .journey-step__diamond.journey-step__diamond--filled {
  background-color: var(--color-voco-light-teal);
}

.journey-step__date-mobile {
  display: none;
}

@media only screen and (max-width: 1080px) {
  .journey-step .date-tile {
    display: none;
  }

  .journey-step__path {
    width: 50px;
  }

  .journey-step__path-bar {
    left: 23px;
    width: 4px;
  }

  .journey-step__no-date {
    display: none;
  }

  .journey-step__diamond {
    border-width: 4px;
    width: 1.25rem;
    height: 1.25rem;
  }

  .journey-step:last-child .journey-step__path-bar {
    bottom: 75px;
    height: calc(100% - 75px);
  }

  .journey-step__date-mobile {
    display: block;
    position: absolute;
    bottom: 1.5rem;
    left: 50px;
    font-size: 0.625rem;
  }

  .journey-step--orange .journey-step__date-mobile {
    color: var(--color-action-orange);
  }
}
