.message-input {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.message-input__avatar {
  width: 4rem;
  margin-right: 1rem;
  flex-shrink: 0;
}

.message-input__input-callout {
  flex-grow: 0;
  flex-shrink: 0;
  z-index: 1;
}

.message-input__input {
  flex-grow: 1;
  position: relative;
}

.message-input__button {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  cursor: pointer;
}

.message-input__in-progress {
  position: absolute;
  right: 3rem;
  bottom: 1.25rem;
}

.message-input__text {
  padding: 1rem;
  width: 100%;
  height: 7rem;
  box-shadow: 0 3px 6px #00000029;
  background-color: var(--color-white);
}

.message-input__text::placeholder {
  color: var(--text-hover-color);
}

.message-input__button-image {
  fill: var(--voco-button-blue);
}

.message-input__button-image:hover {
  fill: var(--voco-button-blue);
}

.message-input__button:disabled .message-input__button-image {
  fill: var(--text-hover-color);
  cursor: default;
}

@media only screen and (max-width: 768px) {
  .message-input__avatar {
    width: 2.25rem;
    margin-right: 0;
  }
}
