/* GLOBAL Vars etc */

html {
  /*  COLOURS */
  --background-color: #f7feff;
  --border-bottom: #7f999f7c;
  --button-grey: #9FB2B5;
  --color-white: #fff;
  --text-hover-color: #B2B2B2;
  --menu-item-hover-color: #F4F4FD;
  --color-voco-faded: #FFAD5F;
  --color-voco-lighter-faded: #FABF88;
  --color-action-orange: #FF6B00;
  --color-motivator-orange: #ED742E;
  --color-title-orange:#F5AD65;
  --color-text-primary: #0C343D;
  --color-voco-teal: #007078;
  --color-voco-darker-teal: #00828A;
  --color-voco-light-teal: #66D1E3;
  --color-voco-grey-teal: #BFD9DB;
  --color-motivator-green: #00B478;
  --voco-button-blue: #40C2D9;
  --voco-light-blue: #DBF5F9;
  --color-modal-background: rgba(1,72,77,0.81);
  --color-meeting-background: rgb(61,61,61);
  --content-width: 68rem;

  /* SPACING */
  --line-spacing: 1.35rem

  /* FONT SIZES */
  --font-size-nav: 0.75rem;
}

body {
  margin: 0;
  font-family: mokoko, -apple-system, 'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
  color: var(--color-text-primary);
}

.link {
  color: var(--voco-button-blue);
  font-weight: 700;
}

.bold {
  font-weight: 700;
}

.p {
  margin-bottom: 1rem;
}

.table tr {
  padding: 5px 0;
}

.table td,
.table th {
  padding: 0 5px;
  vertical-align: middle;
}

.table thead {
  font-weight: 700;
}

.h1 {
  font-family: league-gothic, sans-serif;
  font-size: 2.5rem;
  line-height: 2.75rem;
  text-transform: uppercase;
}

.h2 {
  font-family: league-gothic, sans-serif;
  font-size: 1.875rem;
  line-height: 2rem;
  text-transform: uppercase;
}

.h3 {
  font-family: league-gothic, sans-serif;
  font-size: 1.5rem;
  line-height: 1.75rem;
}