.subscription-confirmation {
  margin-top: 8.5rem;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 5rem 1fr 1fr;
}

.subscription-confirmation__info {
  justify-self: end;
  align-self: start;
  margin-left: 1rem;
  grid-row: 1 / span 2;
}

.subscription-confirmation__image {
  grid-column: 2;
  grid-row: 2 / span 2;
  justify-self: start;
  align-self: end;
}

.subscription-confirmation__info-text {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.825rem;
  text-transform: none;
}

@media only screen and (max-width: 1025px) {
  .subscription-confirmation {
    margin-top: 2rem;
    row-gap: 1rem;

    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
  }

  .subscription-confirmation__info {
    justify-self: center;
    grid-column: 1;
    grid-row: 1;
  }

  .subscription-confirmation__image {
    grid-column: 1;
    grid-row: 2;
    justify-self: end;
  }

  .subscription-confirmation__info-text {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}
