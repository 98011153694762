.avatar__avatar-image {
  width: 100%;
  border: 0 solid transparent;
  border-radius: 50%;
  height: auto;
}

.avatar__avatar-stacked {
  position: relative;
}

.avatar__avatar-stacked--secondary {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
}
