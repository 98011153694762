.step-card {
  display: flex;
  max-width: calc(68rem - 241px);
}

.step-card__title {
  flex-shrink: 1;
}

.step-card__links {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;
}

.step-card--teal .step-card__links {
  border: 6px solid var(--color-voco-light-teal);
}

.step-card--orange .step-card__links {
  border: 6px solid var(--color-voco-lighter-faded);
}

.step-card--dark-orange .step-card__links {
  border: 6px solid var(--color-action-orange);
}

.step-card__link {
  font-family: league-gothic, sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem;
  margin: auto 2rem;
  text-align: center;
  color: var(--color-voco-light-teal);
}

@media only screen and (max-width: 1080px) {
  .step-card {
    max-width: calc(100vw - 82px);
  }

  .step-card__link {
    font-size: 1rem;
    line-height: 1rem;
    max-width: 30px;
    margin: 1rem;
  }
}
