.match-queue-page {
  max-width: 90rem;
  margin: 4rem auto;
}

.match-queue-page__controls {
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;
}

.match-queue-page__controls > * {
  flex-grow: 1;
}

.match-queue-page__clear-search {
  flex-grow: 0;
  align-self: flex-end;
  margin-bottom: 0.25rem;
}

.match-queue-page__button {
  margin-top: 3rem;
  margin-right: 1rem;
}
