.edit-goal-modal__goal {
  display: flex;
}

.edit-goal-modal__goal-number {
  margin-top: 1rem;
  margin-right: 2rem;
  font-size: 2rem;
  font-weight: 800;
  color: var(--color-voco-teal);
}

.edit-goal-modal__goal-title {
  display: block;
  width: 100%;
  border: 1px solid var(--color-text-primary);
  padding: 1rem;
  height: 4.5rem;
  margin-bottom: 1rem;
}
