.title-card {
  padding: 1rem 1.5rem;
}

.title-card--teal {
  background-color: var(--color-voco-light-teal);
  color: var(--color-voco-darker-teal);
}

.title-card--orange {
  background-color: var(--color-voco-lighter-faded);
  color: var(--color-action-orange);
}

.title-card--dark-orange {
  background-color: var(--color-action-orange);
  color: var(--color-white);
}

.title-card-content {
  overflow: hidden;
  display: flex;
}

.title-card__text {
  flex-grow: 1;
}

.title-card__buttons {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.title-card__subtitle {
  font-size: 1.25rem;
}

.title-card__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title-card__button {
  font-weight: 700;
  border-radius: 22px;
}

@media only screen and (max-width: 1080px) {
  .title-card__subtitle {
    font-size: 0.625rem;
    line-height: 0.75rem;
  }

  .title-card__title {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
}
