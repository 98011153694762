.label-input__label {
  display: block;
  margin-bottom: 0.5rem;
}

.label-input__input {
  display: block;
  border: 1px solid var(--text-hover-color);
  font-weight: 700;
  padding: 0.25rem 0.5rem;
  width: 100%;
}

.label-input__input[type='date']::-webkit-calendar-picker-indicator {
  width: 1rem;
  padding: 0px;
  margin: 0px;
  margin-top: 0.5rem;
  background-image: url(../../svgs/icon-dropdown.svg);
  background-repeat: no-repeat;
  /* background-position: calc(100% - 0.5rem) center; */
  -webkit-appearance: none;
}

.label-input--inline {
  display: flex;
}

.label-input--inline .label-input__label {
  margin-right: 10px;
}
