.navbar {
  background-color: var(--color-white);
  border-bottom: 0.2px var(--border-bottom) solid;
  display: flex;
  justify-content: center;
}

.navbar__content {
  padding: 0.5rem 0.5rem;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}

.navbar__items {
  display: flex;
  align-items: center;
}

.navbar__branding {
  margin-top: 0.5rem;
}

.navbar__branding-link {
  display: inline-block;
  line-height: 100%;
}

.navbar__logo {
  display: block;
  width: auto;
  height: 2.5rem;
}

@media only screen and (max-width: 768px) {
  .navbar__logo {
    height: 2rem;
  }

  .navbar__content {
    padding: 0.25rem 0.5rem;
  }
}
