.time-tile {
  border: 2px solid var(--color-voco-teal);
  width: 101px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.time-tile--large {
  width: 101px;
  height: 101px;
}

.time-tile--selected {
  border-color: var(--voco-button-blue);
  background-color: var(--voco-light-blue);
}

.time-tile--disabled {
  cursor: not-allowed;
  border: none;
  background-color: var(--text-hover-color);
}

.time-tile--disabled .time-tile__time,
.time-tile--disabled .time-tile__ampm {
  color: var(--color-white);
}

.time-tile__time,
.time-tile__ampm {
  font-weight: 700;
  color: var(--color-voco-teal);
}

.time-tile__time {
  margin-right: 0.25rem;
}

.time-tile__ampm {
  font-size: 0.75rem;
  line-height: 0.75rem;
}

.time-tile__emoji {
  font-size: 1.5rem;
  position: absolute;
  right: -0.25rem;
  bottom: -0.75rem;
}

@media only screen and (max-width: 1080px) {
  .time-tile {
    width: 50px;
  }

  .time-tile--large {
    width: 70px;
    height: 70px;
  }

  .time-tile__time {
    margin-right: 0.1rem;
  }

  .time-tile__emoji {
    font-size: 1.25rem;
    right: -0.65rem;
    bottom: -0.65rem;
  }
}
