.subscription-banner {
  height: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.link-button--primary.subscription-banner__link {
  color: var(--color-white);
}
