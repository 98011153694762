.new-subscription {
  margin-top: 8.5rem;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 1fr 1fr;
}

.new-subscription__info {
  justify-self: end;
  align-self: center;
}

.new-subscription__info-text {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  line-height: 1.825rem;
}

.new-subscription__thanks {
  font-size: 2.5rem;
  line-height: 3rem;
}

.new-subscription__image {
  grid-column: 1;
  grid-row: 2;
  justify-self: center;
  align-self: end;
}

.new-subscription__pricing-table {
  grid-row: 1 / span 2;
  justify-self: start;
}

@media only screen and (max-width: 1025px) {
  .new-subscription__thanks {
    font-size: 2rem;
    line-height: 2.25rem;
  }
  .new-subscription {
    margin-top: 2rem;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content 1fr;
    row-gap: 1rem;
    max-width: 100%;
  }

  .new-subscription__info-text {
    font-size: 1.25rem;
    line-height: 1.5rem;
    grid-row: 1;
    justify-self: center;
    align-self: start;
  }

  .new-subscription__image {
    grid-row: 3;

    align-self: end;
  }

  .new-subscription__pricing-table {
    grid-row: 2;
    justify-self: center;
  }
}
