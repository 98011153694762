.member-queue-page__existing-matches-section {
  margin-top: 2rem;
}

.member-queue-page {
  margin-top: 3rem;
}

.member-queue-page__matches-section {
  max-width: 110rem;
  margin: 2rem auto;
}

.member-queue-page__matches {
  margin-top: 2rem;
}

.member-queue-page__matches-linkedin {
  text-align: center;
  padding-top: 0.25rem !important;
}

/* TAB BAR Styles */

.member-queue-page__tabs {
  display: flex;
}

.member-queue-page__tabs > * {
  margin-right: 5px;
}

.member-queue-page__tab-button {
  padding-top: 0;
  padding-bottom: 0;
  margin-left: auto;
}

.member-queue-page__controls {
  display: flex;
  gap: 1rem;
  padding-bottom: 1rem;
}

.member-queue-page__controls > * {
  margin-top: 1rem;
  flex-grow: 1;
}

.member-queue-page__clear-search {
  flex-grow: 0;
  align-self: flex-end;
  margin-bottom: 0.25rem;
}
