.journal-entry-card {
  display: flex;
  margin-bottom: 1.5rem;
}

.journal-entry-card__avatar {
  width: 4rem;
  margin-right: 1rem;
  flex-shrink: 0;
}

.journal-entry-card__content {
  border: 4px solid var(--color-voco-teal);
  flex-grow: 1;
  position: relative;
  margin-left: 40px;
  padding: 1rem 1rem 1rem 0;
  display: flex;
}

.journal-entry-card__content-callout {
  position: absolute;
  top: 10px;
  left: -41.3px;
}

.journal-entry-card__content-text {
  white-space: pre-line;
}

.journal-entry-card__content-date {
  border-right: 4px solid var(--color-voco-teal);
  color: var(--color-voco-teal);
  margin-right: 1rem;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100px;
}

.journal-entry-card__content-date-day {
  font-size: 2rem;
  font-weight: 800;
  margin-bottom: 0.4rem;
}

.journal-entry-card__content-date-month {
  font-size: 0.875rem;
  font-weight: 700;
}
.journal-entry-card__content-date-year {
  font-size: 0.875rem;
  font-weight: 700;
}

.journal-entry-card__content-title {
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .journal-entry-card__avatar {
    width: 2.25rem;
    margin-right: 0;
  }

  .journal-entry-card__avatar-image {
    height: 2.25rem;
  }

  .journal-entry-card__avatar-stacked--secondary {
    height: 1.5rem;
    top: 1.25rem;
    left: 1.25rem;
  }

  .journal-entry-card__content-callout {
    top: 5px;
    left: -30px;
    width: 30px;
    height: auto;
  }

  .journal-entry-card__content {
    border-width: 3px;
    padding: 0.5rem 0.5rem 0.5rem 0;
    font-size: 0.725rem;
  }

  .journal-entry-card__content-date {
    margin-right: 0.5rem;
    width: 70px;
    border-width: 3px;
  }

  .journal-entry-card__content-date-day {
    font-size: 1.25rem;
    line-height: 1rem;
    margin-bottom: 0.2rem;
  }

  .journal-entry-card__content-date-month {
    font-size: 0.625rem;
    line-height: 1.25rem;
  }

  .journal-entry-card__content-date-year {
    font-size: 0.625rem;
    line-height: 1.25rem;
  }
}
