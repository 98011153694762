.goal-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.25rem;
  row-gap: 1.5rem;
  margin-bottom: 2rem;
  /* grid-auto-flow: column; */
}

@media only screen and (max-width: 825px) {
  .goal-container {
    grid-template-columns: 1fr;
    row-gap: 0.5rem;
  }
}
