.info-message {
  display: flex;
  gap: 2rem;
  align-items: flex-start;
  justify-content: center;
}

.info-message__message {
  position: relative;
  border: 4px solid var(--color-voco-faded);
  background-color: var(--color-white);
  padding: 1rem;
  width: 32rem;
}

.info-message__message-left {
  margin-right: 6rem;
}

.info-message__message-right {
  margin-left: 6rem;
}

.info-message__message-callout {
  position: absolute;
  width: 27px;
  height: 22px;
  top: 30px;
}

.info-message__message-callout-right {
  right: -27px;
}
.info-message__message-callout-left {
  left: -27px;
}

.info-message__avatar {
  margin-top: 1rem;
  width: 4rem;
}

@media only screen and (max-width: 768px) {
  .info-message__message {
    margin: 0 1rem;
    width: auto;
    max-width: 32rem;
  }

  .info-message__avatar {
    display: none;
  }
}
