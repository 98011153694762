.edit-account__avatar {
  width: 4rem;
  height: 4rem;
  margin-bottom: 1rem;
}

.edit-account__form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 4rem;
  row-gap: 2rem;
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 990px) {
  .edit-account__form {
    grid-template-columns: 1fr;
    row-gap: 0.5rem;
    margin-bottom: 1.5rem;
  }
}
