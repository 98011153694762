.account-page__header {
  display: flex;
  margin-bottom: 2rem;
  align-items: center;
}

.account-page__avatar {
  width: 4rem;
  height: 4rem;
}

.account-page__avatar-info {
  margin-left: 1rem;
}

.account-page__auth-settings {
  margin-top: 2rem;
}

.account-page__subscription {
  margin-top: 2rem;
  font-weight: 700;
}

.account-page__subscription-status {
  background-color: var(--voco-light-blue);
  padding: 0.125rem;
  border: var(--color-voco-grey-teal) 1px solid;
  border-radius: 0.25rem;
}

.account-page__terminate {
  margin-top: 2rem;
}

.account-page__buttons {
  margin-top: 2rem;
}
