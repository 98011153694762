.meeting-scheduler {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.meeting-scheduler__week-view {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.meeting-scheduler__week {
  display: flex;
  opacity: 1;
  transition: opacity 400ms;
  gap: 1rem;
}

.meeting-scheduler__week-button {
  width: 46px;
  height: 101px;
  background-color: var(--color-voco-grey-teal);
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.meeting-scheduler__week-button:disabled {
  opacity: 0;
}

.meeting-scheduler__week--fade {
  opacity: 0;
}

.meeting-scheduler__day,
.meeting-scheduler__times {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.meeting-scheduler__date-header {
  margin-bottom: 1rem;
}

.meeting-scheduler__week-button-text {
  display: none;
  font-weight: 700;
  color: var(--color-voco-teal);
}

@media only screen and (max-width: 1080px) {
  .meeting-scheduler,
  .meeting-scheduler__week-view,
  .meeting-scheduler__week {
    flex-direction: column;
    gap: 0.5rem;
  }

  .meeting-scheduler__day,
  .meeting-scheduler__times {
    flex-direction: row;
    align-items: center;
  }

  .meeting-scheduler__times {
    width: 17rem;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    touch-action: auto;
    padding: 1rem 0;
  }

  .meeting-scheduler__times::-webkit-scrollbar {
    display: none;
  }

  .meeting-scheduler__time {
    scroll-snap-align: start;
  }

  .meeting-scheduler__date-header {
    margin-bottom: 0rem;
  }

  .meeting-scheduler__week-button {
    margin: 0;
    height: 37px;
    width: 100%;
  }

  .meeting-scheduler__week-button-icon {
    display: none;
  }

  .meeting-scheduler__week-button-text {
    display: inline-block;
  }
}
