.match-scores-table__button {
  cursor: pointer;
}

.match-scores-table__button:disabled {
  color: var(--text-hover-color);
  cursor: default;
}

.member-queue-page__matches .label-input__label {
  margin-top: 0.5rem;
}
.member-queue-page__matches .label-input__input {
  margin-bottom: 0.5rem;
  border-color: var(--menu-item-hover-color);
  background-color: var(--color-white);
}

.member-queue-page__member-row {
  border-top: 1px solid var(--text-hover-color);
}

.member-queue-page__member-row--actions {
  padding-bottom: 0.5rem;
}

.member-queue-page__member-row--saving td {
  background-color: var(--menu-item-hover-color);
}
