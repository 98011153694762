.archive-match-modal__member {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 2rem;
}
.archive-match-modal__avatar {
  width: 4rem;
  height: 4rem;
}

.archive-match-modal__firstname {
  margin-top: 0.5rem;
}

.archive-match-modal__lastname {
  margin-bottom: 0.5rem;
}

.archive-match-modal__button {
  padding: 0.25rem 1rem;
}

.archive-match-modal .content-section {
  padding: 1rem 0 0;
}

.archive-match-modal__matches {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 768px) {
  .archive-match-modal__matches {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
  }

  .archive-match-modal__member {
    margin: 0;
  }

  .archive-match-modal .content-section__heading {
    flex-direction: column;
  }

  .archive-match-modal .content-section__info {
    align-self: flex-start;
  }
}
