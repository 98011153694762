.label-select__label {
  display: block;
  margin-bottom: 0.5rem;
}

.label-select__select {
  display: block;
  border: 1px solid var(--text-hover-color);
  font-weight: 700;
  padding: 0.25rem 0.5rem;
  width: 100%;
}
