.text-card {
  background-color: var(--color-white);
  padding: 1rem 1.5rem;
  font-size: 1rem;
}

.text-card__title {
  font-family: league-gothic, sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.text-card__body > :first-child {
  margin-top: 0;
}

.text-card h1 {
  font-family: league-gothic, sans-serif;
  font-size: 2.5rem;
  line-height: 2.75rem;
  text-transform: uppercase;
  margin: 2.5rem auto 1.5rem;
}

.text-card h2 {
  font-family: league-gothic, sans-serif;
  font-size: 1.875rem;
  line-height: 2rem;
  text-transform: uppercase;
  margin: 2rem auto 1.5rem;
}

.text-card h3 {
  font-family: league-gothic, sans-serif;
  font-size: 1.75rem;
  line-height: 1.875rem;
  margin: 1.75rem auto 1.5rem;
}

.text-card h4 {
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 700;
  margin: 1.75rem auto 1.5rem;
}

.text-card h5 {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 700;
  margin: 1.5rem auto 1.5rem;
}

.text-card h6 {
  font-size: 1.1rem;
  line-height: 1.25rem;
  font-weight: 700;
  margin: 1.5rem auto 1.5rem;
}

.text-card p {
  margin-bottom: 1.5rem;
}

.text-card strong {
  font-weight: 700;
}

.text-card em {
  font-style: italic;
  font-weight: 500;
}

.text-card blockquote {
  /* font-family: league-gothic, sans-serif; */
  font-style: italic;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  display: block;
  border-left: 3px solid var(--color-text-primary);
  padding: 0 1rem;
  margin: 1.5rem auto;
}

.text-card figcaption {
  font-size: 0.875rem;
  font-style: italic;
}

.text-card sub {
  vertical-align: sub;
  font-size: smaller;
}

.text-card sup {
  vertical-align: super;
  font-size: smaller;
}

.text-card ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  margin-bottom: 1.5rem;
}

.text-card ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  margin-bottom: 1.5rem;
}

.text-card a {
  color: var(--voco-button-blue);
  font-weight: 700;
}

.text-card image {
  max-width: 100%;
  height: auto;
}

.text-card figure {
  margin-bottom: 1.5rem;
}

.text-card figure[data-rt-type='video'] {
  position: relative;
}

.text-card figure[data-rt-type='video'] > div:first-child {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.text-card figure[data-rt-type='video'] iframe {
  width: 100%;
  height: 100%;
}

.text-card figure[data-rt-align='center'] {
  text-align: center;
}

.text-card figure[data-rt-align='fullwidth'] {
  display: block;
  margin: 0 auto 1.5rem;
}

.text-card figure[data-rt-align='fullwidth'] img {
  display: block;
  width: 100%;
  height: auto;
}

.text-card figure[data-rt-align='floatleft'] {
  float: left;
  width: 50%;
  margin-right: 1.5rem;
}

.text-card figure[data-rt-align='floatright'] {
  float: right;
  width: 50%;
  margin-left: 1.5rem;
}
