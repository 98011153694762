.current-employement-form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 4rem;
  row-gap: 2rem;
  margin-bottom: 1.5rem;
}

.current-employement-form__input--extended {
  grid-column-start: 1;
  grid-column-end: 4;
}

@media only screen and (max-width: 768px) {
  .current-employement-form__input--extended {
    grid-column-start: 1;
    grid-column-end: 1;
  }

  .current-employement-form {
    display: grid;
    row-gap: 1rem;
    grid-template-columns: 1fr;
  }
}
